import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './strategypage.css';
import tradeImg from '../../../assets/images/trade.jpg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import ExtensionIcon from '@mui/icons-material/Extension';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../../Footer';
import Header, { CurrentPage } from '../../Header/Header';
import useWindowSize from '../../Hooks/useWindowSize';
import { getStrategyDetails } from '../AIstrategiesdata';
import { useParams } from 'react-router-dom';
import DailyViewContainer from '../../Results/DailyViewContainer';
import MonthlyViewContainer from '../../Results/MonthlyViewContainer';
import WeekDayViewContainer from '../../Results/WeekDayViewContainer';
import ResultsSummaryContainer from '../../Results/ResultsSummaryContainer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Theme } from '../../../Utils/Constants';
import { ResultsAPIModule } from '../../../API/ResultsAPI';
import TestResultsParser from '../../../Utils/TestResultsParser';
import { BackTestAPIResponse } from '../../../API/API.interface';
import { AuthModule } from '../../../API/Auth';
import { SubscribeToStrategyAPI } from '../../../API/StrategiesAPIS';
import PLLineGraph from '../../Graphs/PLLineGraph/PLLineGraph';
import {
  getSubscriptionDetails,
  subscriptionPauseResumeAPI,
} from '../../../API/LiveTodayAPI';
import { decodeJwtToken } from '../../../API/DecodeJWTFunction';
import { CongratsPopup } from '../CongratsMessagePopUp';
import { UpGradePlanMessagePopup } from '../UpGradePlanPopUp';
import Loader from '../../Dialogs/Loader';
import { MockRunTestsAPI } from '../../../API/StrategiesAPIS';
import { formatNumber } from '../../../API/FormatNumber';
import { formatCAGR } from '../../../API/FormatNumber';
import { StrategyUnsubscribeMessagePopup } from '../UnsubscribePopup';
import { MaxDropDownGraph } from '../../Graphs/MaxDrawdownGraph/MaxDrawDownGraph';
import YearlyPerformance from '../../Graphs/YearlyPerformance/YearlyPerformance';
import TradingCalendar from '../../Graphs/YearlyPerformance/TradingCalander';
import { fetchUserPlanPricing } from '../../../API/ProfileAPI';
import FilterIcon from '../../Vector Components/FilterIcon';
import youtube from '../../../assets/images/youtube.png';
import xsocial from '../../../assets/images/x.png';
import linkedin from '../../../assets/images/linkedin.png';
import instagram from '../../../assets/images/instagram.png';
import facebook from '../../../assets/images/facebook.svg';
import factSheetImg from '../../../assets/images/factsheetimg.png';
import methodlogyImg from '../../../assets/images/methodology.png';
import bookmarkImg from '../../../assets/images/bookmark.png';
import { StrategyCard } from '../StrategyCard';
import { West } from '@mui/icons-material';
import useAppStore from '../../../store/app.store';
import Toast from '../../../Utils/Toast';

const SingleStrategyPage: React.FC = () => {
  const [strategyDetails, setStrategyDetails] = useState<any>(null);
  const [showROIPerct, setROIPerct] = React.useState<boolean>(false);
  const windowSize = useWindowSize();
  const { id } = useParams<{ id: any }>();
  const [showPopup, setShowPopup] = useState(false);
  const [results, setResults] = useState<TestResultsParser>(null as any);
  const [lots, setLots] = useState<number>(0);
  const [termsConditionAccepted, setTermsConditionAccepted] =
    useState<boolean>(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [message, setMessage] = useState('');
  const decodedToken = decodeJwtToken();
  const userId = String(decodedToken?.user_id) || '';
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated, setIsAuthenticated } = useAppStore();
  const [openIndex, setOpenIndex] = useState(-1);
  const [strategyPlanDetails, setStrategyPlanDetails] = useState<any>({});
  const [backtestingPlanDetails, setBacktestingPlanDetails] = useState<any>({});
  const [minCapString, setMinCapString] = useState<string>('');
  const [inSightInfo, setInSightInfo] = useState<any>({});
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const { setSelectedSectionIndex } = useAppStore();
  const { setIsSubscribeNowClicked } = useAppStore();
  const { setSubscribeNowURL } = useAppStore();
  const { setIsExplorePlanClicked } = useAppStore();
  const [isAbsoluteSelected, setIsAbsoluteSelected] = useState(false);

  const handleNavigate = (path: string, options?: { state?: any }) => {
    navigate(path, options);
  };

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  const handleRadioClick = () => {
    setTermsConditionAccepted((prevCondition) => !prevCondition);
  };

  const navigateToPlanandPricing = () => {
    if (isAuthenticated) {
      setSelectedSectionIndex(3);
      navigate('/pricing', { replace: false });
    } else {
      setIsExplorePlanClicked(true);
      navigate('/login');
      window.scrollTo(0, 0); // Scroll to the top of the page directly
    }
  };

  const getResults = async (strategy_id: string) => {
    const accessToken = AuthModule.getInstance().getAccessToken();
    const apiUrl =
      (process.env.REACT_APP_BASE_URL as string) +
      'strategy/master-strategy-performance';
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'x-api-key': process.env.REACT_APP_X_API_KEY as string,
        },
        body: JSON.stringify({
          strategy_id: strategy_id,
          user_id: '215'

        }),
      });
      if (response.ok) {
        const jsonResponse = await response.json();
        // console.log(jsonResponse);
        showResults(jsonResponse as BackTestAPIResponse);
      } else {
        throw new Error('Network response was not ok.');
      }
      setIsLoading(false);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getResults();
  // }, []);

  function showResults(results: BackTestAPIResponse) {
    const optionsParse = new TestResultsParser(results);
    if(optionsParse.resultsLength >0){
      setResults(optionsParse);
    }else{
      setResults(null as any);
    }
  }

  const fetchUserData = async () => {
    const userData = await fetchUserPlanPricing();
    const { strategy, backtesting } = userData.user_plan_details;
    setStrategyPlanDetails(strategy);
    setBacktestingPlanDetails(backtesting);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStrategyDetails(id);
        setStrategyDetails(data.response_data);
        setMinCapString(data.response_data.minimum_investment_capital);
        setInSightInfo(data?.response_data?.insights_information);
        getResults(data.response_data.strategy_id);
      } catch (error) {
        console.error('Error fetching strategy details:', error);
      }
    };
    fetchData();
    if (localStorage.getItem('isAuthenticated') === 'true') {
      fetchUserData();
      setTimeout(() => {
        localStorage.removeItem('isAuthenticated');
      }, 1000);
    }
  }, [id, userId]);

  const requestData = {
    strategy_id: `${strategyDetails?.strategy_id}`,
    lots: lots || 0,
    is_active: true,
    strategy_name: `${strategyDetails?.strategy_name}`,
  };

  const handleSubscribe = async () => {
    if (termsConditionAccepted) {
      if (lots > strategyPlanDetails.max_lots) {
        // UpGradePlanMessagePopup();
        UpGradePlanMessagePopup(strategyPlanDetails.max_lots);
      } else {
        try {
          const apiResponse = await SubscribeToStrategyAPI(requestData);
          setMessage(apiResponse.message);
          // congratsMessagePopup(requestData.strategy_name);
          setIsVisible(true);
        } catch (error: any) {
          setMessage(error.message);
          //alert(error.message);
          if (error.message == undefined) {
            Toast.error('Internal Server Error');
          } else {
            Toast.info(error.message);
          }
        }
      }

      setShowPopup(false);
      setTermsConditionAccepted(false);
      setLots(0);
    } else {
      setMessage('Accept the terms and condition');
      // alert('Accept the terms and condition');
    }
  };

  const handleSubscribePopUp = () => {
    if (!isAuthenticated) {
      setIsSubscribeNowClicked(true);
      setSubscribeNowURL('/strategypage/' + strategyDetails.strategy_id);
      navigate('/login');
      window.scrollTo(0, 0); // Scroll to the top of the page directly
    } else {
      setShowPopup(true);
    }
  };

  const handleUnSubscribe = async () => {
    try {
      const result = await subscriptionPauseResumeAPI(requestData.strategy_id);
      // console.log(result);

      if (result === 'Unsubscribed successfully.') {
        StrategyUnsubscribeMessagePopup(requestData.strategy_name);
        setIsSubscribed(false);
      } else {
      }
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleCancelClick = () => {
    setShowPopup(false);
    setTermsConditionAccepted(false);
    setLots(0);
  };

  function getDailyResultsContainer() {
    return (
      <div style={{ ...styles.container, ...styles.marginTopper }}>
        <StrategyPerformance name={'Trade Log'} />
        <DailyViewContainer key='DailyViewContainer' results={results} />
      </div>
    );
  }

  function getMonthlyViewContainer() {
    return (
      <div style={{ ...styles.container, ...styles.marginTopper }}>
        <StrategyPerformance name={'Month Wise Breakup'} />
        <MonthlyViewContainer
          key='monthlyViewContainer'
          showPerct={showROIPerct}
          margins={results?.getMargins()}
          results={results?.getMonthlyView()}
        />
      </div>
    );
  }

  function getWeekDayViewContainer() {
    return (
      <div style={{ ...styles.container, ...styles.marginTopper }}>
        <StrategyPerformance name={'Day Wise Breakup'} />
        <WeekDayViewContainer
          key='weeklyDayView'
          showPerct={showROIPerct}
          margins={results?.getMargins()}
          results={results?.getWeekDayView()}
        />
      </div>
    );
  }

  function getResultsSummaryContainer() {
    return (
      <div style={styles.container}>
        <StrategyPerformance name={'Key Parameters'} />
        <ResultsSummaryContainer
          key='resultsSummaryContainer'
          data={
            isAbsoluteSelected
              ? results?.getResultsSummary()
              : results?.getResultsSummaryPct()
          }
          isAbsoluteSelected={!isAbsoluteSelected}
        />
      </div>
    );
  }

  function getMaxDrawDownContainer() {
    return (
      <div style={styles.container}>
        {/* <StrategyPerformance name={'Key Parameters'} /> */}
        <MaxDropDownGraph
          key='resultsSummaryContainer'
          results={results.rawResults}
        />
      </div>
    );
  }

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  useEffect(() => {
    const fetchSubsriptionDetailsData = async () => {
      try {
        const details = await getSubscriptionDetails();

        const responseDataArray = details.Response_data || [];

        responseDataArray.forEach((data) => {
          if (id === data.strategy_id) {
            if (data.is_active) {
              setIsSubscribed(true);
            } else {
              setIsSubscribed(false);
            }
          }
        });
      } catch (error: any) {}
    };
    fetchSubsriptionDetailsData();
    // if (message != '') {
    //   showPopUpMessage(message);
    // }
  }, [message]);

  // useEffect(() => {
  //   AuthModule.getInstance()
  //     .isAuthenticated()
  //     .then((isAuthenticated) => {
  //       setIsAuthenticated(isAuthenticated);
  //       if (!isAuthenticated) {
  //         navigate('/home');
  //       }
  //     })
  //     .catch((error) => {
  //       setIsAuthenticated(false);
  //     });
  // }, [navigate]);

  const handleBlogPostClick = () => {
    if (inSightInfo && inSightInfo.blog_post_object_url) {
      window.open(inSightInfo.blog_post_object_url, '_blank');
    }
  };

  const handleMethodologyPostClick = () => {
    if (inSightInfo && inSightInfo.methodology_object_url) {
      window.open(inSightInfo.methodology_object_url, '_blank');
    }
  };

  const handleFactSheetPostClick = () => {
    if (inSightInfo && inSightInfo.factsheets_object_url) {
      window.open(inSightInfo.factsheets_object_url, '_blank');
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const handleClick = () => {
    setIsAbsoluteSelected(!isAbsoluteSelected);
  };
  // if (isVisible) {
  //   return <CongratsPopup strategy_name={requestData.strategy_name} />;
  // }

  // console.log(strategyDetails);

  function StrategyPerformance(params: { name: string }) {
    if (params.name === 'Key Parameters') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p
            style={
              windowSize <= 500
                ? styles.metricsHeaderResponsiveNoMarginBottom
                : styles.metricsHeader
            }
            className='relative fit'
          >
            {params.name}{' '}
            <div
              className={
                windowSize <= 500 ? 'small-dot-responsive' : 'small-dot'
              }
            ></div>
          </p>
          <div
            style={{
              borderRadius: '5px',
              width: '172px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              height: '27px',
              boxShadow: isAbsoluteSelected
                ? 'none'
                : 'inset 4px 4px 5px 0px #BBBE189C',
              backgroundColor: '#FFE484',
              marginBottom: Theme.gap15,
            }}
          >
            <button
              style={{
                backgroundColor: isAbsoluteSelected
                  ? '#FFFFFF'
                  : 'rgba(0,0,0,0)',
                margin: '0',
                color: '#000000B2',
                height: '100%',
                padding: '4px 0px 3px 8px',
                width: '92px',
                boxShadow: isAbsoluteSelected
                  ? 'inset 0px 1px 4px 0px #00000040'
                  : 'none',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                textAlign: 'left',
              }}
              onClick={handleClick}
            >
              Percentage
            </button>
            <button
              style={{
                backgroundColor: isAbsoluteSelected
                  ? 'rgba(0,0,0,0)'
                  : '#FFFFFF',
                margin: '0',
                color: '#000000B2',
                height: '100%',
                padding: '4px 0px 3px 8px',
                width: '80px',
                boxShadow: isAbsoluteSelected
                  ? 'none'
                  : 'inset 0px 1px 4px 0px #00000040',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                textAlign: 'left',
              }}
              onClick={handleClick}
            >
              Absolute
            </button>
          </div>
          {/* {windowSize > 500 && (
            <span
              onClick={() => alert('Filter')}
              className='filter-span-single-strategy-page'
            >
              {' '}
              <FilterIcon /> Filters
            </span>
          )} */}
        </div>
      );
    } else {
      return (
        <p
          style={
            windowSize <= 500
              ? styles.metricsHeaderResponsive
              : styles.metricsHeader
          }
          className='relative fit'
        >
          {params.name} <div className='small-dot-responsive'></div>
        </p>
      );
    }
  }

  function UpIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='7'
        height='14'
        fill='none'
        viewBox='0 0 7 14'
      >
        <path
          fill='#F82929'
          d='M4.428 3.612v9.746H2.87V3.612H.534L3.65.374l3.115 3.238H4.428z'
        ></path>
      </svg>
    );
  }

  function CustomArrowIcon() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='14'
        fill='none'
        viewBox='0 0 12 14'
      >
        <path
          fill='#2747DD'
          d='M5.102 1.675l.595-.655a.6.6 0 01.908 0l5.206 5.73a.756.756 0 010 1l-5.206 5.732a.6.6 0 01-.908 0l-.595-.654a.76.76 0 01.01-1.012L8.34 8.431H.643C.287 8.43 0 8.115 0 7.723v-.944c0-.392.287-.707.643-.707H8.34L5.113 2.686a.754.754 0 01-.01-1.011z'
        ></path>
      </svg>
    );
  }

  return (
    <>
      {isVisible && <CongratsPopup strategyName={requestData.strategy_name} />}
      <Header currentPage={CurrentPage.AI_Strategies} />
      <div
        className={
          windowSize > 900
            ? 'aistrategy-page-container'
            : 'aistrategy-page-container-responsive'
        }
      >
        {windowSize <= 500 ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <West
              onClick={() => {
                handleNavigate('/strategy');
              }}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                left: 6,
                top: 16,
                width: '22px',
              }}
            />
            <StrategyCard AIStrategiesdata={[strategyDetails]} special={true} />
          </div>
        ) : (
          <div className='single-ai-card-container'>
            <div className='single-ai-strategy'>
              <div className='single-ai-strategy-1'>
                <img
                  src={strategyDetails.strategy_image || tradeImg}
                  className='single-ai-strategy-1-1'
                  alt='trade img'
                />
                <div className='single-ai-strategy-1-2'>
                  <div className='single-ai-strategy-1-2-strategy'>
                    {strategyDetails?.strategy_name}
                    <div className='single-ai-strategy-1-2-strategy-1'>
                      Free Access
                    </div>
                    <div
                      style={{
                        color:
                          strategyDetails?.risk === 'High Risk'
                            ? '#F82929'
                            : strategyDetails?.risk === 'Medium Risk'
                            ? '#E7AF1D'
                            : '#2ACD1C',
                      }}
                      className='single-ai-strategy-1-2-strategy-2 flex flex-center'
                    >
                      {/* <div
                        className='badge flex-center risk-badge'
                        
                      > */}
                      {strategyDetails?.risk === 'Low Risk' ? (
                        <svg
                          // className='icon'
                          style={{
                            height: '17px',
                            width: '10px',
                            margin: '1px 2px 0 2px',
                          }}
                          xmlns='http://www.w3.org/2000/svg'
                          width='6'
                          height='11'
                          viewBox='0 0 12 17'
                          fill='none'
                        >
                          <path
                            d='M5 11.2825L5 0.610352L3 0.610352L3 11.2825L0 11.2825L4 14.828L8 11.2825H5Z'
                            fill='#2ACD1C'
                          />
                        </svg>
                      ) : strategyDetails?.risk === 'Medium Risk' ? (
                        <svg
                          // className='icon'
                          style={{
                            height: '17px',
                            width: '15px',
                            margin: '1px 2px 0 2px',
                          }}
                          xmlns='http://www.w3.org/2000/svg'
                          // width='8'
                          // height='18'
                          viewBox='0 0 17 18'
                          fill='none'
                        >
                          <path
                            d='M11 13.171V6.94191H9V13.171H6L10 16.7165L14 13.171H11ZM4 0.72168L0 4.26721H3V10.4963H5V4.26721H8L4 0.72168Z'
                            fill='#E7AF1D'
                          />
                        </svg>
                      ) : (
                        <svg
                          // className='icon'
                          style={{
                            height: '12px',
                            width: '10px',
                            margin: '1px 2px 0 2px',
                          }}
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 8 12'
                          fill='none'
                        >
                          <path
                            d='M3.90316 3.33741L3.90316 10.8369H2.57103L2.57103 3.33741H0.572825L3.2371 0.8459L5.90137 3.33741H3.90316Z'
                            fill='#F82929'
                          />
                        </svg>
                      )}

                      {strategyDetails?.risk}
                    </div>
                  </div>
                  <div className='single-ai-strategy-1-2-author'>
                    by {strategyDetails?.author}
                  </div>
                  <div className='single-ai-strategy-1-2-desc'>
                    {strategyDetails?.strategy_description}
                  </div>
                </div>
              </div>
              <div className='single-ai-strategy-2'>
                <div className='single-ai-strategy-2-1'>
                  <div className='single-ai-strategy-2-title'>
                    Capital Deployed
                  </div>
                  <div className='single-ai-strategy-2-sub'>
                    ₹ {formatNumber(strategyDetails?.capital_deployed)}
                  </div>
                </div>
                {/* <div className='single-ai-strategy-2-2'>
                  <div className='single-ai-strategy-2-title'>CAGR</div>
                  <div className='single-ai-strategy-2-sub single-ai-strategy-2-sub-special'>
                    {formatCAGR(strategyDetails?.cagr_pct)}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
        <br />
        <div
          className={
            windowSize <= 900
              ? 'single-aistrategy-page-middle-section-responsive'
              : 'single-aistrategy-page-middle-section'
          }
        >
          <div
            className={`${
              windowSize <= 500
                ? 'trending-box-responsive trending-box'
                : 'trending-box'
            } trending-box-special`}
          >
            <div
              className={
                windowSize <= 500
                  ? 'trending-box-title-responsive'
                  : 'trending-box-title'
              }
            >
              <div className='svg-icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='22'
                  viewBox='0 0 22 22'
                  fill='none'
                >
                  <path
                    d='M18 8L19.25 5.25L22 4L19.25 2.75L18 0L16.75 2.75L14 4L16.75 5.25L18 8ZM10.5 8.5L8 3L5.5 8.5L0 11L5.5 13.5L8 19L10.5 13.5L16 11L10.5 8.5ZM18 14L16.75 16.75L14 18L16.75 19.25L18 22L19.25 19.25L22 18L19.25 16.75L18 14Z'
                    fill='#E7AF1D'
                  />
                </svg>
              </div>
              &nbsp;
              <span>Trending</span>
            </div>
            <p>{strategyDetails?.trending}</p>
          </div>
        </div>
        {/*  */}
        <br />
        <div className='page-content-body'>
          <div
            className={
              windowSize <= 900
                ? 'heading-section-responsive'
                : 'heading-section'
            }
          >
            <div
              className='overview-heading'
              style={{ marginLeft: '13px', paddingLeft: '10px' }}
            >
              Overview
            </div>
            <div className='overview-heading-underline'></div>
          </div>

          <div
            className={
              windowSize <= 900
                ? 'content-section-responsive'
                : 'content-section'
            }
          >
            <div
              className={
                windowSize <= 900 ? 'first-half-responsive' : 'first-half'
              }
            >
              <div
                className={
                  windowSize <= 900
                    ? 'overview-body-responsive'
                    : 'overview-body'
                }
              >
                <div className='overview-content'>
                  <div className='overview-content-heading'>
                    <article>About the Moneyy.ai</article>
                  </div>
                  <p className='overview-info'>{strategyDetails?.overview}</p>
                  {strategyDetails?.faqs.map((data: any, index: number) => {
                    return (
                      <div className='overview-dropdown-qna' key={index}>
                        <p
                          className='overview-question'
                          onClick={() => toggleFAQ(index)}
                        >
                          {data.question}
                          <KeyboardArrowDownIcon
                            style={{
                              transform:
                                openIndex === index ? 'rotate(180deg)' : 'none',
                            }}
                          />
                        </p>
                        {openIndex === index && (
                          <p className='overview-answer'>{data.answer}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              className={
                windowSize <= 900 ? 'second-half-responsive' : 'second-half'
              }
            >
              {windowSize <= 900 && (
                <div
                  className={
                    windowSize <= 900 ? 'info-card-responsive' : 'info-card'
                  }
                >
                  <div
                    className='info-card-section'
                    onClick={handleBlogPostClick}
                  >
                    <div className='info-card-icon'>
                      {/* <BookmarkAddIcon /> */}
                      <img src={bookmarkImg} alt='bookmark img' />
                    </div>
                    <div className='info-card-content'>
                      <div className='info-card-heading'>Blog Post</div>
                      <div className='info-card-desc'>
                        {inSightInfo?.blog_post_text}
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className='info-card-section'
                    onClick={handleMethodologyPostClick}
                  >
                    <div className='info-card-icon'>
                      {/* <ExtensionIcon /> */}
                      <img src={methodlogyImg} alt='methodology img' />
                    </div>
                    <div className='info-card-content'>
                      <div className='info-card-heading'>Methodology</div>
                      <div className='info-card-desc'>
                        {inSightInfo?.methodology_text}
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className='info-card-section'
                    onClick={handleFactSheetPostClick}
                  >
                    <div className='info-card-icon'>
                      {/* <FileCopyIcon /> */}
                      <img src={factSheetImg} alt='factsheet img' />
                    </div>
                    <div className='info-card-content'>
                      <div className='info-card-heading'>Factsheet</div>
                      <div className='info-card-desc'>
                        {inSightInfo?.factsheets_text}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/*  */}
              {/* pop up window */}
              {showPopup && (
                <div className='subscribe-popup-card'>
                  <div className='popup-card-header'>
                    <article>Choose the Lots Multiplier</article>
                    <br />

                    <div className='popup-card-row'>
                      <article>Total Lots</article>
                      {/* <input type='text' placeholder='Enter Lots' /> */}
                      <input
                        type='number'
                        id='lots'
                        name='lots'
                        value={lots}
                        placeholder='Enter Lots'
                        onChange={(e) => setLots(parseInt(e.target.value, 10))}
                      />
                    </div>
                    <p
                      style={{
                        fontSize: '14.5px',
                        color: '#000000B2',
                        fontWeight: '400',
                      }}
                    >
                      ( 1 Lot is the minimum capital required = ₹ {minCapString}{' '}
                      )
                    </p>

                    <div className='popup-card-terms-conditions flex-center'>
                      {/* <input
                        style={{ border: 'solid', marginTop: '-7px' }}
                        type='radio'
                        id='radioButton'
                        name='radioGroup'
                        value='option1'
                        checked={termsConditionAccepted}
                        onClick={handleRadioClick}
                      /> */}
                      <span
                        style={{
                          display: 'flex',
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          border: '0.7px solid #333',
                          backgroundColor: termsConditionAccepted
                            ? '#2747DD'
                            : 'transparent',
                          cursor: 'pointer',
                          textAlign: 'center',
                          lineHeight: '20px',
                          color: termsConditionAccepted ? '#fff' : '#333',
                          marginTop: '-14px',
                        }}
                        className='flex flex-center'
                        onClick={handleRadioClick}
                      >
                        {termsConditionAccepted && <span>&#10003;</span>}
                      </span>
                      <span>
                        By Clicking on Subscribe button, you agree to our
                        <a href='/terms-and-conditions'>
                          {' '}
                          Terms and Conditions{' '}
                        </a>
                        and <a href='/privacy-policies'> Privacy Statement</a>.
                      </span>
                    </div>

                    <br />

                    <div className='popup-card-button-row'>
                      {/* <button
                        className='popup-subscribe-btn'
                        onClick={handleSubscribe}
                      >
                        {' '}
                        <DoneIcon
                          style={{ width: '16px', height: '36px' }}
                        />{' '}
                        Subscribe
                      </button> */}
                      {termsConditionAccepted ? (
                        <button
                          className='popup-subscribe-btn'
                          onClick={handleSubscribe}
                        >
                          <DoneIcon style={{ width: '16px', height: '36px' }} />{' '}
                          Subscribe
                        </button>
                      ) : (
                        <button
                          className='popup-subscribe-btn'
                          onClick={handleSubscribe}
                          disabled
                        >
                          <DoneIcon style={{ width: '16px', height: '36px' }} />{' '}
                          Subscribe
                        </button>
                      )}
                      <button
                        className='popup-close-btn'
                        onClick={handleCancelClick}
                      >
                        <CloseIcon style={{ width: '16px', height: '36px' }} />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className='strategy-subscribe-card'>
                <div className='strategy-subscribe-card-heading'>
                  Minimum Investment Capital
                </div>
                <div className='strategy-subscribe-card-amount'>
                  ₹ {strategyDetails?.minimum_investment_capital}
                </div>
                <div
                  className='strategy-subscribe-card-plans'
                  onClick={navigateToPlanandPricing}
                >
                  Explore strategy Pricing plans
                  <CustomArrowIcon />
                </div>
                {isSubscribed ? (
                  <button
                    className='subscribe-now-btn'
                    onClick={handleUnSubscribe}
                  >
                    Unsubscribe
                  </button>
                ) : (
                  <button
                    className='subscribe-now-btn'
                    onClick={handleSubscribePopUp}
                  >
                    Subscribe Now
                  </button>
                )}

                <div className='subscribe-social-share-section'>
                  <article style={{ fontSize: '13px' }}>Share on</article>
                  <div
                    style={{ cursor: 'pointer' }}
                    className='strategy-subscribe-card-social-icons'
                  >
                    <img
                      src={xsocial}
                      className='strategy-subscribe-card-social-icon'
                      alt='x'
                      onClick={() => {
                        // navigate("/learn");
                        window.open('https://twitter.com/moneyy_ai', '_blank');
                      }}
                    />

                    <img
                      src={instagram}
                      className='strategy-subscribe-card-social-icon'
                      alt='instagram'
                      onClick={() => {
                        // navigate("/learn");
                        window.open(
                          'https://www.instagram.com/_moneyy.ai/',
                          '_blank'
                        );
                      }}
                    />

                    {/* <img
                      src={facebook}
                      className='strategy-subscribe-card-social-icon'
                      alt='facebook'
                    /> */}

                    <img
                      src={linkedin}
                      className='strategy-subscribe-card-social-icon'
                      alt='linkedin'
                      onClick={() => {
                        // navigate("/learn");
                        window.open(
                          'https://www.linkedin.com/company/moneyy-ai/',
                          '_blank'
                        );
                      }}
                    />

                    <img
                      src={youtube}
                      className='strategy-subscribe-card-social-icon'
                      alt='youtube'
                      onClick={() => {
                        // navigate("/learn");
                        window.open(
                          'https://www.youtube.com/@ManiMoneyy?sub_confirmation=1 ',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Blog Card */}
              {windowSize > 900 && (
                <div className='info-card'>
                  <div
                    className='info-card-section'
                    onClick={handleBlogPostClick}
                  >
                    <div className='info-card-icon'>
                      {/* <BookmarkAddIcon /> */}
                      <img src={bookmarkImg} alt='bookmark img' />
                    </div>
                    <div className='info-card-content'>
                      <div className='info-card-heading'>Blog Post</div>
                      <div className='info-card-desc'>
                        {inSightInfo?.blog_post_text}
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className='info-card-section'
                    onClick={handleMethodologyPostClick}
                  >
                    <div className='info-card-icon'>
                      {/* <ExtensionIcon /> */}
                      <img src={methodlogyImg} alt='methodology img' />
                    </div>
                    <div className='info-card-content'>
                      <div className='info-card-heading'>Methodology</div>
                      <div className='info-card-desc'>
                        {inSightInfo?.methodology_text}
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className='info-card-section'
                    onClick={handleFactSheetPostClick}
                  >
                    <div className='info-card-icon'>
                      {/* <FileCopyIcon /> */}
                      <img src={factSheetImg} alt='factsheet img' />
                    </div>
                    <div className='info-card-content'>
                      <div className='info-card-heading'>Factsheet</div>
                      <div className='info-card-desc'>
                        {inSightInfo?.factsheets_text}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {results === null && (
        <div className='strategyMetrics'>
          <h1>No Strategy Performance available</h1>
        </div>
      )}
      {results != null && (
        <div className='strategyMetrics'>
          <div
            className={
              windowSize <= 900
                ? 'heading-section-responsive'
                : 'heading-section'
            }
          >
            <div
              className='overview-heading pb-13'
              style={{ marginTop: '42px' }}
            >
              Strategy Performance
            </div>
            <div className='overview-heading-underline'></div>
          </div>
          <div className='graphContainer'>{getResultsSummaryContainer()}</div>
          <div className='graphContainer'>{getWeekDayViewContainer()}</div>
          <div className='graphContainer estr'>{getMonthlyViewContainer()}</div>
          <div className='graphContainer specialGraphContainer'>
            <TradingCalendar
              results={results.rawResults}
              avgCapital={results.getResultsSummary().estimatedMargin}
            />
          </div>
          <div className='graphContainer'>
            <PLLineGraph
              unsortedData={results.rawResults.response_data.relative_performance}
              strategyName={requestData.strategy_name}
            />
          </div>
          <div className='graphContainer'>
            {/* <MaxDropDownGraph /> */}
            {getMaxDrawDownContainer()}
          </div>
          <div className='graphContainer'>{getDailyResultsContainer()}</div>

          {/* <div className='graphContainer'>
            <YearlyPerformance />
          </div> */}
        </div>
      )}
      <Footer />
    </>
  );
};

const styles = {
  marginTopper: {
    marginTop: '4px',
  },
  metricsHeader: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginBottom: 1,
    paddingBottom: Theme.gapSmall,
    width: 'fit-content',
    fontWeight: Theme.fontWeight.semiBold,
    borderBottom: '2px solid ' + Theme.colors.whiteGrey70,
    marginTop: '20px',
  },
  metricsHeaderResponsive: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: '0.63px solid ' + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: '9.27px',
    marginBottom: '33px',
  },
  metricsHeaderResponsiveNoMarginBottom: {
    color: Theme.colors.black70,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    borderBottom: '0.63px solid ' + Theme.colors.black70,
    margin: 0,
    padding: 0,
    paddingBottom: '9.27px',
  },
  headerText: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginTop: Theme.gapLarge,
  },
  buttonIconGrey: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h2,
    marginRight: Theme.gapTiny,
    margin: 'auto',
    verticalAlign: 'middle',
  },
  container: {
    // backgroundColor: Theme.colors.backgroundF3,
    padding: Theme.gapSmall,
    borderRadius: Theme.borderRadiusLarge,
  },
};

export default SingleStrategyPage;
